<template>
  <b-card class="p-lg-1">
    <div class="row justify-content-center align-items-center py-5">
      <div class="col-xs-12 col-sm-10 col-md-6 col-lg-6">
        <img
          :src="require('@/assets/images/logo/track-and-trace.png')"
          class="img-fluid"
        />
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <SearchID />
      </div>
    </div>
  </b-card>
</template>

<script>
import SearchID from "./SearchID.vue";

export default {
  components: {
    SearchID,
  },
};
</script>

<style></style>
