<template>
  <b-card class="mx-0 px-0 d-flex justify-content-center">
    <b-card-text class="h1 font-large-1 text-primary">
      TRACK &amp; TRACE
    </b-card-text>
    <b-container>
      <validation-observer ref="idValidation">
        <b-form class="auth-login-form">
          <!-- Order ID / Delivery ID Input -->
          <b-form-group label="Search Order ID / Delivery ID" class="mb-2">
            <validation-provider
              #default="{ errors }"
              name="ID"
              rules="required"
            >
              <b-form-input
                id="id"
                v-model="form.id"
                :state="errors.length > 0 ? false : null"
                name="id"
                placeholder="Insert Order ID / Delivery ID"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group class="d-flex justify-content-center">
            <validation-provider
              #default="{ errors }"
              name="reCAPTCHA"
              rules="required"
            >
              <VueRecaptcha
                ref="recaptcha"
                v-model="form.recaptcha"
                :sitekey="sitekey"
                @verify="handleVerify"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>

      <div class="d-flex justify-content-center align-items-center my-2">
        <b-button
          variant="gradient-primary"
          class="w-50 box-shadow-1"
          type="submit"
          @click="goToSummary"
        >
          <span v-if="isLoading">
            <b-spinner />
          </span>
          <span v-else>Submit</span>
        </b-button>
      </div>
    </b-container>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  components: {
    VueRecaptcha,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      form: {
        id: "",
        recaptcha: null,
      },
      required,
      sitekey: "6LeQ8pEjAAAAAC-JQA64qpgZ4iTwqB-AftUOu615",
      isLoading: false,
    };
  },

  methods: {
    goToSummary() {
      this.$refs.idValidation.validate().then(async (success) => {
        if (success) {
          this.isLoading = true;
          this.$http.get(`get_order/${this.form.id}`);
          const orderIdResponse = await this.$http.get(
            `get_order/${this.form.id}`
          );
          if (Object.keys(orderIdResponse.data.data).length === 0) {
            const deliveryIdResponse = await this.$http.get(
              `get_route_orders?route_id=${this.form.id}`
            );
            if (deliveryIdResponse.data.status === false) {
              this.isLoading = false;
              this.$swal({
                title: "ID does not exist",
                text: "Order ID/Delivery ID does not exist or is invalid.Please check entry and try again.",
                icon: "error",
                showCancelButton: false,
                customClass: {
                  confirmButton: "btn-danger",
                },
              });
              return;
            }

            this.$swal({
              title: "ID Found!",
              icon: "success",
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonText: "Proceed",
              cancelButtonText: "Cancel",
              customClass: {
                cancelButton: "btn-secondary",
                confirmButton: "btn-success",
              },
            }).then((deliveryResult) => {
              if (deliveryResult.isConfirmed) {
                this.$router.push({
                  name: "track-delivery",
                  params: { id: this.form.id },
                });
              } else {
                this.isLoading = false;
              }
            });
            return;
          }

          this.$swal({
            title: "ID Found!",
            icon: "success",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: "Proceed",
            cancelButtonText: "Cancel",
            customClass: {
              cancelButton: "btn-secondary",
              confirmButton: "btn-success",
            },
          }).then((orderResult) => {
            if (orderResult.isConfirmed) {
              this.$router.push({
                name: "track-order",
                params: { id: this.form.id },
              });
            } else {
              this.isLoading = false;
            }
          });
        }
      });
    },
    handleVerify(response) {
      this.form.recaptcha = response;
    },
  },
};
</script>

<style></style>
